@import '../../assets/mixins';

@card-list-item-background: rgba(0, 21, 41, 0.02);
@border-radius: 4px;
@orange: #a03200;
@disabledBorderColor: #00152914;
@disabledBorderBackgroundColor: #fff;
@disabledBoxShadowColor: #00000040;

.datasource-dashboard .section-content,
.google-drive-dashboard .section-content {
  margin-top: 0;
}

.page.data-sources {
  .section-header {
    margin-bottom: 0;
    border-bottom: 1px solid rgba(0, 21, 41, 0.16);
  }
  .data-sources-table {
    border: none;
    background-color: transparent;

    .lb-tabs-line {
      margin-bottom: var(--size-16px);
    }

    .table-filters {
      .lb-table-filters-drawer {
        .label.filter-selected {
          color: var(--lb-color-purple-500);
        }
      }
      .lb-table-filters-button {
        .label {
          color: var(--lb-color-font-primary);
          padding: 0;
          margin-left: 0;
        }
      }
    }

    .actions-button {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      gap: 1rem;
      background: #fff;

      .btn-print {
        border: 1px solid #d1d5da;
        border-radius: 4px;
        margin-right: 0.5rem;
        padding: 0.5rem 1rem;
        height: 2.6rem;
      }
    }
    .table-card-view .action-buttons {
      align-items: start;
    }
    .controls {
      padding-top: 1.5rem;
      background: transparent;
    }
    .table-cards-listing {
      // margin-top: -1rem;
      background: none;
      border: none;
    }
    .title-column {
      a {
        display: flex;
        align-items: center;
        .icon-external {
          visibility: hidden;
        }
        &:hover {
          color: var(--color-purple);
          .icon-external {
            visibility: visible;
          }
        }
      }
    }

    .accessible-column {
      margin-left: -1.5rem;
      .accessible-container {
        padding: 0.5rem;
        .item {
          border-left: 1px solid #e1e4e8;
          padding-left: 1rem;
          margin-bottom: 0.5rem;
          width: 80px;

          &:not(:first-of-type) {
            margin-left: 1rem;
          }

          .count {
            margin-bottom: -0.25rem;
          }
        }
      }
    }

    .is-external-link {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      .icon-external {
        opacity: 0;
        margin-left: 0.5rem;
      }

      &:hover {
        .icon-external {
          opacity: 1;
        }
      }
    }

    .expanded-section {
      padding: 2rem 3rem;
      width: 100%;
      overflow-y: auto;
      position: relative;

      .item-row {
        margin-top: 1.1rem;

        .item {
          margin-left: 0.5rem;

          &.ant-tag {
            margin-top: 0.5rem;
          }
        }
      }

      .label {
        width: 8rem;
        flex-shrink: 0;
        margin-bottom: 0;
      }

      .attributes-list {
        flex-flow: row wrap;
      }
    }
  }
}
.data-sources {
  div.ui.header {
    font-size: inherit;
    background-color: var(--color-white);
    h1 {
      margin: 0;
    }
  }

  .ds-name-files {
    display: flex;
    align-items: center;

    img {
      max-width: 1.8rem;
      max-height: 1.8rem;
      height: auto;
    }
  }

  .progress-bar {
    flex-grow: 1;
    background-color: var(--color-white);
    border-radius: 4px;
    height: 4px;
    margin-top: -1px;
    background-color: var(--color-structured-data-bg);

    .progress-completed {
      height: 100%;
      background: var(--color-primary-light);
      border-radius: 4px;
    }
  }

  .status-container {
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    svg {
      min-width: 1.5rem;
    }
  }

  .label {
    border-radius: 2px;
    color: var(--color-structured-databases-label);
    font-size: 0.92rem;
    font-weight: 600;
    padding: 0.3rem 0.8rem;
    margin-left: 0.3rem;

    &.progress {
      background-color: var(--color-structured-databases-bg-label-completed);
    }
  }

  .executive-summary-btn {
    margin-top: 1.2rem;
  }
}

.confirm-ds-delete .actions {
  .ui.button:not(.primary) {
    .button-secondary();
  }

  .ui.button.primary {
    .button-primary();
  }
}

@media screen and (max-width: 767px) {
  div.data-sources {
    margin: 0 auto;

    .ui.grid {
      .row > .column.mobile-icons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
}

.structured-form {
  margin: 0 !important;
  header.ui.header.page-header h1 {
    color: var(--color-page-title);
    .font-bold(1.43rem, 1.4em);
  }

  .dashboard-content .section-content {
    padding: 1.5rem;
    background-color: var(--color-page-bg);
  }

  .category-tabs {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    .nav-tabs {
      display: inline-flex;

      .nav-item {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        .font-normal(1rem, 1.22em);
        padding: 0.86rem 1.15rem;

        & + .nav-item {
          margin-left: 1.48rem;
        }

        &:not(.active) {
          background-color: var(--color-white);
          &:hover {
            box-shadow: 0px 2px 2px var(--color-nav-hover-shadow);
            border: 1px solid var(--color-nav-hover-border);
          }
        }
      }
    }

    .ui.button.ellipsis-menu-options {
      min-width: 3rem;
      height: 3rem;
    }
  }

  .list {
    margin-top: 1.58rem;

    .category-info {
      .category-datsource-types {
        display: flex;
        flex-flow: row wrap;
      }

      .category-title {
        margin-bottom: 0.72em;
      }

      & + .category-info {
        margin-top: 0.86rem;
      }

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 1rem;
        font-weight: 500;
        text-decoration: none;
        color: var(--color-table-text);
        background-color: var(--color-white);
        border-radius: 6px;
        border: 1px solid var(--color-datasource-type-box-border);
        width: 16rem;
        height: 11.43rem;
        &:hover {
          box-shadow: 0px 0.6px 1.8px rgba(36, 41, 46, 0.1), 0px 3.2px 7.2px rgba(36, 41, 46, 0.13);
          border: 1px solid var(--color-datasource-type-box-border-hover);
        }

        &.disabled {
          color: var(--color-table-text) !important;
          pointer-events: initial !important;
          opacity: 1 !important;
          position: relative;
          &:hover {
            &:before {
              content: 'BETA';
              position: absolute;
              color: var(--color-risk-medium);
              font-size: 0.8rem;
              right: 1.5rem;
              top: 1rem;
            }
          }
        }
        &.ds-existing {
          border: none;
          cursor: not-allowed;
          border-radius: 6px;
          border: 1px solid @disabledBorderColor;
          background: @disabledBorderBackgroundColor;
          box-shadow: 0px 4px 4px 0px @disabledBoxShadowColor !important;
          opacity: 0.65 !important;

          &:hover::before {
            content: '';
          }
        }

        .icon:not(.button) {
          height: 3.75rem;
          &.jira {
            height: 4.75rem;
          }
        }

        span {
          .font-medium(1rem, 1.43em);
          text-transform: none;
        }
      }
    }
  }

  .list-search {
    margin: 1.75rem 0;
    margin-bottom: 1.43rem;
    max-width: 18.6rem;
    .search .input input {
      font-size: 1rem;
      line-height: 1.43em;
      padding-left: 2.86rem !important;
      border: 1px solid var(--color-list-search-control-border);
      border-radius: 3px;
      height: unset;
    }
    svg {
      position: absolute;
      height: 100%;
      left: 1.2rem;
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    text-decoration: none;
    color: var(--color-table-text);
    background: none;
    border-radius: 6px;
    border: 1px solid #d1d5da;
    width: 224px;
    height: 160px;

    &:hover {
      box-shadow: 0px 0.6px 1.8px rgba(36, 41, 46, 0.1), 0px 3.2px 7.2px rgba(36, 41, 46, 0.13);
      border: 1px solid #5d6bce;
      background: none;
    }

    &.disabled {
      color: var(--color-table-text) !important;
    }

    .icon:not(.button) {
      height: 3.75rem;
    }

    .icon.snowflake {
      height: 2rem;
    }
    .icon.one-drive {
      height: 1.5rem;
    }
    .icon.share-point {
      height: 2rem;
    }
    .icon.aws-s3 {
      height: 3rem;
    }
    .icon.elastic-search {
      height: 2rem;
    }
    .icon.oracle {
      width: 6rem;
      height: 4rem;
    }
    .icon.gdrive,
    .icon.gmail,
    .icon.slack {
      height: 2.3rem;
      span {
        .font-medium(1rem, 1.43em);
        color: var(--color-datasource-category-datasource-type);
      }
    }
    .icon.outlook {
      height: 3.4rem;
    }
  }
}

.ds-list-by-entity {
  background-color: var(--color-page-bg);
  flex-grow: 1;
  .controls {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;

    .separator {
      width: 1px;
      height: auto;
      margin: 0 1.3rem;
      background-color: var(--color-entities-separator);
    }
  }

  .search-wrapper,
  .buttons-container {
    margin-bottom: 1rem;
  }

  .buttons-container {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .table-legend {
    padding: 0.5rem 0rem;
    margin-bottom: 0.5rem;
  }

  .search-wrapper {
    flex-grow: 1;

    .ui.input {
      input {
        border-radius: 6px;
      }
    }
  }
  .dataSources-table {
    .data-source {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      img {
        max-width: 1.5rem;
        max-height: 1.5rem;
      }
    }
  }

  .ds-list-table-container {
    border-radius: 4px;
  }
}

.unstructured-summary-widget-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
}

.dataSources-table {
  border: 1px solid var(--lb-color-grey-light-6);
  border-radius: var(--size-4px);
}

.data-sources-summary {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  // justify-content: center;
  overflow: auto;
  height: 100%;
  // background-color: var(--color-white);
  border-radius: 8px;
  gap: 1.1428rem;

  .entity-empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &.legend-pos-right {
    .entity-empty-state {
      justify-content: center;
      align-items: center;
      display: flex;
      flex-grow: 1;
    }
  }
  .content-container {
    flex-grow: 1;
    display: flex;
    flex-flow: column;
    gap: 16px;
  }
  .total {
    display: flex;
    flex-shrink: 0;
    border-radius: @border-radius;
    background: rgba(0, 21, 41, 0.04);
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    // margin: 1rem 0;
    padding: 1.1428rem 0;
  }
  .main-totals {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-around;
    background: rgba(0, 21, 41, 0.04);
    border-radius: @border-radius;
    padding: 1.1428rem;

    .summary-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .at-risk {
        color: @orange;
      }
    }
  }
  .totals {
    display: flex;
    flex-direction: column;
    gap: 0.857rem;
    .item {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--color-river-bed);
      .item-text-wrapper {
        display: flex;
        gap: 0.37rem;
        align-items: center;
        justify-content: center;
        .text-icon {
          display: flex;
          align-self: center;
        }
      }

      .icon-box {
        display: flex;
        align-items: center;
        height: 1.5rem;
      }
    }
  }
}

.ui.modal.modal-view.modal-db-scan-options {
  .modal-view-container {
    .header {
      border-bottom: none;
    }
  }
  .checkbox-section {
    border: 1px solid #e5e8fd;
    box-shadow: 0px 1.2px 3.6px rgba(36, 41, 46, 0.1), 0px 6.4px 14.4px rgba(36, 41, 46, 0.13);
    border-radius: 8px;
    padding: 1.2rem 2rem;
    cursor: pointer;
    user-select: none;
    margin-top: 1rem;

    .radio-custom {
      font-weight: 600;
      font-size: 1rem;
    }

    .info {
      margin-left: 1.9rem;
      margin-top: 0.5rem;
    }

    .info-items {
      font-size: 0.86rem;
      padding: 0;
      margin: 0;
      margin-left: 3.5rem;
      margin-top: 0.3rem;
    }

    .snapshot-offline-alert {
      display: flex;
      gap: 0.5rem;
      padding: 1rem;
      background: #f6edd9;
      box-shadow: 4px 0px 0px 0px #fc9d03 inset;
      margin-top: 1rem;

      .alert-icon {
        margin-top: 0.25rem;
      }

      .snapshot-offline-alert-text {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.label-row-content {
  display: flex;
  gap: 4px;
  align-items: center;
  .selected-labels {
    margin-top: 0;
  }
  .more-text {
    font-size: 12px;
    color: #586069;
  }
}
